import classNames from 'classnames';
import { memo, ReactNode, useCallback, useState } from 'react';
import { flushSync } from 'react-dom';

import { GalleryCardSelectionButton } from '~/components/Gallery/GalleryCard/GalleryCardSelectionButton';
import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { GetItemMenuOptions } from '~/constants/MenuOptions';
import { useSelectableCard, UseSelectableCardReturnType } from '~/hooks/useSelectableCard';
import { useSelectionAwareContextMenu } from '~/hooks/useSelectionAwareContextMenu';
import { SelectableGalleryItem } from '~/store/selectedItems/types';

export interface GalleryCardProps<Item extends SelectableGalleryItem> {
  item: Item;
  testId: string;
  title: string;
  getItemMenuOptions: GetItemMenuOptions;
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
  isSelectable: boolean;
  onClick?: () => void;
  children: (
    props: UseSelectableCardReturnType & { setIsMenuOpen: (isOpen: boolean) => void; isMenuOpen: boolean },
  ) => ReactNode;
  getButtonClassName?: (props: { isSelected: boolean }) => string;
}

function _GalleryCard<Item extends SelectableGalleryItem>({
  item,
  testId,
  title,
  getItemMenuOptions,
  getSelectionMenuOptions,
  isSelectable = true,
  onClick,
  children,
  getButtonClassName,
}: GalleryCardProps<Item>) {
  const {
    onBlur,
    onFocus,
    handleHover,
    isHovering,
    handleItemClick,
    handleItemDoubleClick,
    handleUnhover,
    confirmItemSelection,
    preSelectItem,
    itemRef,
    isSelected,
    isHighlighted,
  } = useSelectableCard({
    item,
    onClick,
    isSelectable,
  });

  /** Sub Menu */
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const contextMenuOptions = getItemMenuOptions({ trackLocation: 'gallery-item-context-menu' });

  /** Context Menu */
  const { openContextMenu, getContextMenu, isShowingContextMenu } = useSelectionAwareContextMenu({
    contextMenuOptions,
    id: item.id,
    trackLocationForMultiSelect: 'gallery-multi-select-context-menu',
    selectionMenuOptions: getSelectionMenuOptions('gallery-multi-select-context-menu'),
  });

  const onHover = useCallback(() => {
    flushSync(() => {
      handleHover();
    });
  }, [handleHover]);

  /**
   * If the menu is open, we want to keep the hover state active
   */
  const _isHovering = isHovering || isMenuOpen;

  return (
    <>
      <div
        data-testid={testId}
        data-asset-title={title}
        data-test-isselected={isSelected}
        className={classNames('flex h-full flex-col')}
        onContextMenu={(event) => {
          if (isMenuOpen) return;

          return openContextMenu(event);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseEnter={onHover}
        onMouseLeave={handleUnhover}
        onMouseMove={onHover}
        ref={itemRef}
      >
        <GalleryCardSelectionButton
          confirmItemSelection={confirmItemSelection}
          isSelectable={isSelectable}
          onClick={onClick}
          onDoubleClick={handleItemDoubleClick}
          preSelectItem={preSelectItem}
          isHovering={_isHovering}
          isHighlighted={isHighlighted || isSelected}
          className={getButtonClassName?.({ isSelected })}
        />
        {children({
          setIsMenuOpen,
          isMenuOpen,
          onBlur,
          onFocus,
          handleHover,
          isHovering: _isHovering,
          handleItemClick,
          handleItemDoubleClick,
          handleUnhover,
          confirmItemSelection,
          preSelectItem,
          itemRef: itemRef,
          isSelected,
          isHighlighted,
        })}
      </div>
      {isHovering || isShowingContextMenu ? getContextMenu() : null}
    </>
  );
}

export const GalleryCard = memo(_GalleryCard) as typeof _GalleryCard;
