import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { GallerySection } from '@air/component-gallery-view';
import { useBreakpointsContext } from '@air/provider-media-query';
import { Upload } from '@air/redux-uploader';
import { constant, noop } from 'lodash';
import { useCallback, useMemo } from 'react';

import { CardSize } from '~/classes/CardSizeStore';
import { GalleryItemType, GalleryViewRender } from '~/components/Gallery/types';
import { UploadingFileGalleryCard } from '~/components/Gallery/UploadingFileGalleryCard';
import { useGallerySectionFooter } from '~/components/Gallery/useGallerySectionFooter';
import { useGallerySectionHeader } from '~/components/Gallery/useGallerySectionHeader';
import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';
import DragType from '~/components/Shared/Drag/dragTypes';
import { WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN } from '~/constants/WorkspaceSpacing';
import useCSSGridLikeLayout from '~/hooks/useCSSGridLikeLayout';
import { UseGalleryDataInfo } from '~/swr-hooks/gallery/types';

export const FileGalleryCardSize: { [key in CardSize]: number } = {
  small: 124,
  medium: 166,
  large: 249,
  'extra-large': 332,
};

export interface UseGalleryFilesParams<C> {
  data?: UseGalleryDataInfo<C>;
  containerWidth: number;
  renderFile: ((props: GalleryViewRender<C>) => JSX.Element) | undefined;
  loadMore?: () => void;
  uploads?: Upload[];
  isFirstSection: boolean;
  containerHorizontalPadding?: number;
  itemHeight: number;
  itemWidth?: number;
  isSelectable?: (file: C) => boolean;
}

export const useGalleryFiles = <C extends Clip | PublicClip>({
  renderFile,
  containerWidth,
  loadMore = noop,
  data,
  uploads = [],
  isFirstSection,
  containerHorizontalPadding = WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN,
  itemHeight,
  itemWidth,
  isSelectable = constant(false),
}: UseGalleryFilesParams<C>): GallerySection<GalleryMetadata> | null => {
  const { isAboveSmallScreen, isAboveMediumScreen } = useBreakpointsContext();

  const files = useMemo(
    () => [
      ...uploads.map((u) => ({
        item: u,
        type: GalleryItemType.upload,
      })),
      ...(data?.items ?? []).map((u) => ({
        item: u,
        type: GalleryItemType.file,
      })),
    ],
    [data?.items, uploads],
  );

  const total = (data?.total ?? 0) + uploads.length;

  const cardDesktopWidth = itemWidth ?? itemHeight * 1.25;

  const { boxes: fileBoxes } = useCSSGridLikeLayout({
    height: itemHeight,
    minWidth: !isAboveMediumScreen ? 300 : cardDesktopWidth,
    itemCount: files.length,
    containerWidth,
    containerHorizontalPadding,
    itemHorizontalSpacing: !isAboveSmallScreen ? 0 : 24,
  });

  const renderItem = useCallback(
    (index: number) => {
      const file = files[index];
      if (file) {
        return file.type === GalleryItemType.upload ? (
          <UploadingFileGalleryCard
            width={
              /**
               * Uploads don't inherently have a width and get it from the ratio of the
               * row height
               */
              itemHeight
            }
            upload={file.item as Upload}
          />
        ) : (
          renderFile?.({
            box: fileBoxes[index],
            data: file.item as C,
            index,
          })
        );
      }
      return null;
    },
    [files, itemHeight, renderFile, fileBoxes],
  );

  const getItemData = useCallback(
    (index: number): GalleryMetadata => ({
      type: files[index].type,
      isSelectable: files[index].type === GalleryItemType.file ? isSelectable(files[index].item as C) : false,
      itemId: files[index].item.id,
      canDragTo: (item) => item.type === DragType.file,
    }),
    [files, isSelectable],
  );

  const { isHeaderCollapsed, header } = useGallerySectionHeader({
    isFirstSection,
    title: `Files (${total.toLocaleString()})`,
    containerWidth,
    containerPadding: containerHorizontalPadding,
  });

  const footer = useGallerySectionFooter({
    isLoading: !!data?.isLoading,
    loadMore,
    hasMore: !!data?.hasMore,
    containerWidth,
  });

  if (!total && !data?.isLoading) {
    return null;
  }

  return {
    header: !!total ? header : undefined,
    footer: isHeaderCollapsed ? undefined : footer,
    items: {
      render: renderItem,
      boxes: isHeaderCollapsed ? [] : fileBoxes,
      verticalItemsSpacing: !isAboveSmallScreen ? 12 : 24,
      getData: getItemData,
    },
  };
};
