import { CustomFieldValue } from '@air/api/types';

import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';

export const chipToCustomField = (
  chip: DefaultChipType,
  customFieldValues: CustomFieldValue[],
): CustomFieldValue | null => {
  return customFieldValues.find((value) => value.id === chip.value) || null;
};

export const chipsToCustomField = (
  chips: DefaultChipType[],
  customFieldValues: CustomFieldValue[],
): CustomFieldValue[] => {
  return chips
    .map((chip) => {
      return customFieldValues.find((o) => o.id === chip.value) || null;
    })
    .filter((fieldValue) => !!fieldValue) as CustomFieldValue[];
};
