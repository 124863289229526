import { Calendar } from '@air/next-icons';
import { reportErrorToBugsnag } from '@air/utils-error';
import { Box, Button, InputProps, TXProp } from '@air/zephyr';
import { useId } from '@reach/auto-id';
import { format } from 'date-fns';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { DateInput } from '~/components/Zephyr/DateInput/DateInput';
import { parseAirDateToISO } from '~/utils/DateUtils';

export interface EditableDateProps extends Pick<InputProps, 'placeholder' | 'id' | 'variant' | 'name' | 'label'> {
  'data-testid'?: string;
  disabled?: boolean;
  isEditing?: boolean;
  /**
   * This will set the max character length for the textarea.
   */
  onEditingStateChange?: (isEditingState: boolean) => void;
  readOnly?: boolean;
  tx?: TXProp & {
    EditableDateInput?: TXProp;
  };
  dateValue?: string;
  onChange: (value: Date | undefined) => void;
  shouldShowCalendarIcon?: boolean;
}

export const EditableDate = ({
  ['data-testid']: testId,
  disabled,
  isEditing = false,
  id,
  label,
  onEditingStateChange = noop,
  placeholder,
  readOnly,
  tx = {},
  name,
  dateValue = '',
  variant = 'field-input-chonky',
  onChange,
  shouldShowCalendarIcon = true,
}: EditableDateProps) => {
  const autoId = useId(id)!;
  const [isEditingState, setIsEditingState] = useState(isEditing);
  const isPreviousIsEditing = usePrevious(isEditingState);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      setIsEditingState(isEditing);
      onEditingStateChange(isEditing);
    },
    [onEditingStateChange],
  );

  const buttonText = useMemo(() => {
    /** `dateValue` shows Invalid Date on Safari, so we reformat it here */
    const formattedDate = dateValue ? parseAirDateToISO(dateValue).toString() : undefined;
    try {
      return formattedDate ? format(new Date(formattedDate), 'MM/dd/yyyy') : placeholder;
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: 'Failed to format date in EditableDate',
        metadata: {
          dateValue,
          formattedDate,
        }
      })

      return '';
    }

  }, [dateValue, placeholder]);

  useEffect(() => {
    if (isEditingState && dateInputRef?.current) {
      dateInputRef.current?.focus();
      dateInputRef.current.setSelectionRange(dateInputRef.current.value.length, dateInputRef.current.value.length);
    }

    if (!isEditingState && isPreviousIsEditing && buttonRef?.current) {
      buttonRef.current.focus();
    }
  }, [buttonRef, isEditingState, isPreviousIsEditing, dateInputRef]);

  const { EditableDateInput: inputStyles, ...containerStyles } = tx;

  return (
    <Box
      data-testid={testId}
      tx={{
        display: 'flex',
        flexGrow: 1,
        mx: 0,
        my: 0,
        borderRadius: 4,
        cursor: disabled ? 'not-allowed' : readOnly ? 'default' : 'pointer',
        ...containerStyles,
      }}
    >
      {isEditingState ? (
        <>
          <DateInput
            testId="DATE_INPUT_FIELD"
            shouldShowCalendarIcon={shouldShowCalendarIcon}
            onSelect={(_, e) => {
              e?.stopPropagation();
            }}
            autoFocus
            selected={dateValue ? parseAirDateToISO(dateValue) : undefined}
            variant={variant}
            placeholderText={placeholder}
            // timeouts are so clear button has a chance to trigger before close/blur do which unrender the component
            onCalendarClose={() => setTimeout(() => setIsEditing(false), 100)}
            onBlur={() => setTimeout(() => setIsEditing(false), 100)}
            onChange={(date) => {
              setIsEditing(false);
              onChange(date);
            }}
            id={autoId}
            label={label}
            name={name}
            ref={dateInputRef}
            tx={{
              width: '100%',
              ...inputStyles,
            }}
          />
        </>
      ) : (
        <Button
          adornmentLeft={shouldShowCalendarIcon ? Calendar : undefined}
          disabled={disabled ?? readOnly}
          onClick={() => {
            setIsEditing(true);
          }}
          ref={buttonRef}
          tx={{
            alignItems: 'flex-start',
            flexGrow: 1,
            justifyContent: 'flex-start',
            px: 8,
            py: 8,
            borderRadius: 4,
            color: dateValue ? 'var(--colors-grey11)' : 'var(--colors-grey7)',
            fontFamily: 'inherit',
            fontFeatureSettings: 'inherit',
            fontSize: 'inherit',
            fontWeight: dateValue ? 'inherit' : 'regular',
            letterSpacing: 'inherit',
            lineHeight: 'inherit',
            textAlign: 'inherit',

            '&:hover': {
              backgroundColor: 'var(--colors-grey1)',
              color: dateValue ? 'inherit' : 'var(--colors-grey11)',
            },

            '&:active': {
              backgroundColor: 'var(--colors-grey1)',
              color: dateValue ? 'inherit' : 'var(--colors-grey11)',
            },

            '&:focus-visible': {
              backgroundColor: 'var(--colors-grey1)',
              boxShadow: 'none',
            },

            '&:disabled': {
              color: readOnly ? (dateValue ? 'inherit' : 'pigeon300') : 'pigeon200',
            },
          }}
          variant="button-unstyled"
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
