import { AirActionTrackingLocation } from '@air/analytics';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { itemIsSelectedSelector, selectedItemsCountSelector } from '@air/redux-selected-items';

import { MenuSelectedHeader } from '~/components/Menus/MenuSelectedHeader';
import { useContextMenu, UseContextMenuInfo } from '~/hooks/useContextMenu';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

export interface UseSelectionAwareContextMenuParams {
  contextMenuOptions: DropdownMenuOption[];
  id: string;
  trackLocationForMultiSelect: AirActionTrackingLocation;
  selectionMenuOptions: DropdownMenuOption[];
}

export function useSelectionAwareContextMenu({
  id,
  contextMenuOptions,
  selectionMenuOptions,
}: UseSelectionAwareContextMenuParams): UseContextMenuInfo {
  const store = useAirStore();
  const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, id));
  const selectedCount = selectedItemsCountSelector(store.getState());

  return useContextMenu({
    childrenTop: isSelected ? <MenuSelectedHeader selectedCount={selectedCount} /> : null,
    id: `selection-aware-context-menu-${id}`,
    options: selectedCount > 1 && isSelected ? selectionMenuOptions : contextMenuOptions,
  });
}
