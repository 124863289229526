import { getRegistrationValidationSchema } from '@air/utils-auth';
import { Formik, FormikProps } from 'formik';
import { ReactNode } from 'react';
import { InferType } from 'yup';

import Form from '~/components/Form';
import PasswordInput from '~/components/Form/PasswordInput';
import { FormikInput } from '~/components/Zephyr/Formik/FormikInput';
import {
  SIGN_UP_EMAIL_INPUT,
  SIGN_UP_FIRST_NAME_INPUT,
  SIGN_UP_LAST_NAME_INPUT,
  SIGN_UP_PASSWORD_INPUT,
} from '~/constants/testIDs';
import { PASSWORD_REQS_STRING } from '~/utils/Auth';

import { TermsCheckbox } from './TermsCheckbox';

const _SignUpValidationSchema = getRegistrationValidationSchema();
export type SignUpUserType = InferType<typeof _SignUpValidationSchema>;

export interface EmailSignUpFormProps {
  defaultEmail: string | undefined;
  formID: string;
  onSubmit: (user: SignUpUserType) => void;
  disabled?: boolean;
  termsCheckbox?: ReactNode;
  initialValues?: Partial<SignUpUserType>;
  children?: (formikProps: FormikProps<SignUpUserType>) => ReactNode;
}

export const EmailSignUpForm = ({
  defaultEmail,
  formID,
  onSubmit,
  disabled,
  initialValues,
  termsCheckbox = <TermsCheckbox />,
  children,
}: EmailSignUpFormProps) => {
  const validationSchema = getRegistrationValidationSchema(defaultEmail);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...validationSchema.cast({}),
        ...initialValues,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount={true}
    >
      {(formikProps) => (
        <Form id={formID}>
          <FormikInput
            label="Work email"
            placeholder="olivia@companyname.com"
            type="email"
            required={true}
            name="email"
            autoComplete="username"
            spacingStyles={{ mb: 16 }}
            variant="field-input-smol"
            data-testid={SIGN_UP_EMAIL_INPUT}
            disabled={disabled}
          />
          <div className="mb-4 flex [&>div]:min-w-0">
            <FormikInput
              label="First name"
              type="text"
              required={true}
              name="firstName"
              autoFocus
              autoComplete="given-name"
              variant="field-input-smol"
              spacingStyles={{ mr: 8 }}
              data-testid={SIGN_UP_FIRST_NAME_INPUT}
              disabled={disabled}
            />

            <FormikInput
              label="Last name"
              type="text"
              required={true}
              name="lastName"
              autoComplete="family-name"
              variant="field-input-smol"
              data-testid={SIGN_UP_LAST_NAME_INPUT}
              disabled={disabled}
            />
          </div>

          <PasswordInput
            description={PASSWORD_REQS_STRING}
            spacingStyles={{ mb: 16 }}
            testId={SIGN_UP_PASSWORD_INPUT}
            disabled={disabled}
          />
          {children?.(formikProps)}
          {termsCheckbox}
        </Form>
      )}
    </Formik>
  );
};
