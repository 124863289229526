import { Clip } from '@air/api/types';
import { memo } from 'react';

import FileExtensionPreview from '~/components/FileExtensionPreview';
import { getExtStyles } from '~/utils/FileExtensionUtils';

type AssetGalleryCardFailedTranscodeProps = Pick<Clip, 'ext'>;

export const AssetGalleryCardFailedTranscode = memo(({ ext }: AssetGalleryCardFailedTranscodeProps) => {
  return (
    <div
      className="flex flex-1 flex-col items-center justify-center"
      style={{
        /**
         * Todo: get rid of theme. to do that though,
         * getExtStyles (and all underlying functions) will need to be refactored
         */
        backgroundColor: getExtStyles(ext).bgColor,
      }}
    >
      <FileExtensionPreview
        ext={ext}
        text="No preview available"
        color="color"
        size="medium"
        tx={{ justifyContent: 'center', flexGrow: 1 }}
      />
    </div>
  );
});

AssetGalleryCardFailedTranscode.displayName = 'AssetGalleryCardFailedTranscode';
