import { Account, Auth } from '@air/api';

export type SSOOption = Extract<
  Awaited<ReturnType<typeof Account.getAccountSSOOptionsV2>>,
  { data: any }
>['data'][number];

export const getProviderNameFromSSOOptions = (ssoOptions: SSOOption) => {
  return 'organizationId' in ssoOptions
    ? Auth.convertOrganizationIdToSAMLProvider(ssoOptions.organizationId)
    : Auth.convertWorkspaceIdToSAMLProvider(ssoOptions.workspaceId);
};

export const getIdFromSSOOptions = (ssoOptions: SSOOption) => {
  return 'organizationId' in ssoOptions ? ssoOptions.organizationId : ssoOptions.workspaceId;
};
