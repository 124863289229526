import { useLoadMoreLogic, UseLoadMoreLogicParams } from '~/components/LoadMoreSpinner/useLoadMoreLogic';
import Spinner from '~/components/UI/Spinner';

interface LoadMoreSpinnerProps extends UseLoadMoreLogicParams {
  /** Offset of loader in container, 64 is default */
  topOffset?: number;
}

/**
 * This component calls loadMore function if it becomes visible to a user
 */
const LoadMoreSpinner = ({ loadMore, isVisible, isLoading, topOffset = 64 }: LoadMoreSpinnerProps) => {
  useLoadMoreLogic({ loadMore, isVisible, isLoading });

  return (
    <div className="relative" data-testid="LOAD_MORE_SPINNER" style={{ top: topOffset }}>
      <div key={0} className="flex h-16 items-start justify-center">
        <Spinner />
      </div>
    </div>
  );
};

export default LoadMoreSpinner;
