import { Eye, EyeClosed } from '@air/next-icons';
import { Button } from '@air/zephyr';
import VisuallyHidden from '@reach/visually-hidden';
import { useToggle } from 'react-use';

import { FormikInput } from '~/components/Zephyr/Formik/FormikInput';
import { InputProps } from '~/components/Zephyr/Input';

interface PasswordInputProps extends Partial<Pick<InputProps, 'className' | 'spacingStyles' | 'disabled'>> {
  label?: string;
  name?: string;
  testId?: string;
  description?: string;
}

const PasswordInput = ({
  className,
  description,
  testId,
  spacingStyles,
  label,
  name,
  disabled,
}: PasswordInputProps) => {
  const fieldID = 'Password';
  const [isValueVisible, togglePasswordVisibility] = useToggle(false);

  return (
    <FormikInput
      className={className}
      label={label || 'Password'}
      type={isValueVisible ? 'text' : 'password'}
      required
      autoComplete="new-password"
      description={{
        isHidden: false,
        component: (
          <>
            {description}
            <VisuallyHidden>Password visibility control exists after input.</VisuallyHidden>
          </>
        ),
      }}
      name={name || 'password'}
      rightAdornment={
        <Button
          onClick={togglePasswordVisibility}
          aria-controls={fieldID}
          aria-expanded={isValueVisible}
          variant="button-unstyled"
          tx={{
            color: 'inherit',
            '> svg': { width: '100%' },
          }}
        >
          <VisuallyHidden>{isValueVisible ? 'Hide password' : 'Show password'}</VisuallyHidden>
          {!disabled && (isValueVisible ? <Eye /> : <EyeClosed />)}
        </Button>
      }
      data-testid={testId}
      spacingStyles={spacingStyles}
      variant="field-input-smol"
      disabled={disabled}
    />
  );
};

export default PasswordInput;
