import { Account, Auth } from '@air/api';
import { WORKSPACE_INTEGRATION_SSO_NOT_ENABLED } from '@air/errors';
import { Button } from '@air/zephyr';
import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { FormikConfig } from 'formik/dist/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import React, { memo, useCallback } from 'react';
import * as Yup from 'yup';

import Form from '~/components/Form';
import { FormikInput } from '~/components/Zephyr/Formik/FormikInput';
import { Routes } from '~/constants/routes';
import { CONTINUE_WITH_PASSWORD, LOGIN_EMAIL_INPUT, LOGIN_SUBMIT_BUTTON, SAML_SSO_FORM } from '~/constants/testIDs';
import { getAccountsSSOOptionsKey } from '~/hooks/useGetAccountsSSOOptions';
import { isAirrorInstance } from '~/swr-hooks/isAirrorInstance';
import { callOnFullAccountUserPool } from '~/utils/callOnFullAccountUserPool';
import { sanitizeEmail } from '~/utils/EmailUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getProviderNameFromSSOOptions } from '~/utils/SSOUtils';

interface SSOLoginSchemaValues {
  email: string;
}

const SSOLoginSchema = Yup.object()
  .shape({
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Please enter a valid email address.')
      .default(''),
  })
  .required();

export interface SamlSSOLoginFormProps {
  onContinueWithPasswordClick: () => void;
  onRedirect?: () => void;
  customState: object | undefined;
}

export const SamlSSOLoginForm = memo(
  ({ onContinueWithPasswordClick, onRedirect, customState }: SamlSSOLoginFormProps) => {
    const { maintenanceModeJson } = useFlags();
    const isFormDisabled = !!maintenanceModeJson?.maintenanceMessage;
    const { push } = useRouter();
    const queryClient = useQueryClient();
    const {
      selectWorkspaceAuth,
    }: {
      selectWorkspaceAuth?: {
        domains?: string[];
      };
    } = useFlags();

    const onSubmit: FormikConfig<SSOLoginSchemaValues>['onSubmit'] = useCallback(
      async (values, { setSubmitting, setErrors }) =>
        callOnFullAccountUserPool(async () => {
          const userEmail = sanitizeEmail(values.email);

          try {
            setSubmitting(true);
            const result = await Account.getAccountSSOOptionsV2({ email: userEmail });

            if (isAirrorInstance(result) || result.data.length === 0) {
              queryClient.setQueryData(
                getAccountsSSOOptionsKey(userEmail),
                isAirrorInstance(result) ? [] : result.data,
              );
              setSubmitting(false);
              setErrors({
                email: WORKSPACE_INTEGRATION_SSO_NOT_ENABLED.message,
              });
            } else {
              queryClient.setQueryData(getAccountsSSOOptionsKey(userEmail), result.data);
              const userEmailDomain = userEmail.split('@')[1];
              const domains = selectWorkspaceAuth?.domains;

              if (domains?.includes(userEmailDomain) && result.data.length > 1) {
                push({
                  pathname: Routes.selectSSOProviderToLoginTo,
                  query: {
                    email: userEmail,
                    customState: customState ? JSON.stringify(customState) : undefined,
                  },
                });
              } else {
                const ssoOption = result.data[0];

                try {
                  const samlProvider = getProviderNameFromSSOOptions(ssoOption);

                  if (samlProvider) {
                    Auth.ssoSamlSignin(samlProvider, {
                      customState,
                    });
                  } else {
                    throw new Error(`Missing samlProvider!`);
                  }

                  onRedirect?.();
                } catch (error) {
                  reportErrorToBugsnag({
                    error,
                    context: 'Failed login user using saml sso',
                    metadata: { ssoOption },
                  });

                  setErrors({ email: 'An error occurred. Please try again later' });
                }
              }
            }
          } catch (error) {
            setSubmitting(false);
            reportErrorToBugsnag({
              error,
              context: 'Failed get account SSO options',
              metadata: { updatedAccount: { email: userEmail } },
            });

            setErrors({ email: 'An error occurred. Please try again later' });
          }
        }),
      [customState, onRedirect, push, queryClient, selectWorkspaceAuth?.domains],
    );

    return (
      <Formik initialValues={SSOLoginSchema.cast({})} validationSchema={SSOLoginSchema} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <>
            <Form data-testid={SAML_SSO_FORM}>
              <FormikInput
                label="Work email"
                type="email"
                name="email"
                placeholder="Add your email"
                autoComplete="username"
                spacingStyles={{ mb: 24 }}
                variant="field-input-smol"
                data-testid={LOGIN_EMAIL_INPUT}
                disabled={isFormDisabled}
              />
              <Button
                type="submit"
                size="medium"
                tx={{ width: '100%', mt: 8 }}
                isLoading={isSubmitting}
                data-testid={LOGIN_SUBMIT_BUTTON}
                disabled={isFormDisabled}
              >
                Log in
              </Button>
            </Form>
            <div className="mt-5 flex w-full justify-center">
              <button
                data-testid={CONTINUE_WITH_PASSWORD}
                className="text-14 font-medium text-grey-10 underline hover:text-grey-12"
                onClick={onContinueWithPasswordClick}
              >
                Continue with Google or password
              </button>
            </div>
          </>
        )}
      </Formik>
    );
  },
);

SamlSSOLoginForm.displayName = 'SamlSSOLoginForm';
