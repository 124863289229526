import {
  AssetCustomFieldValue,
  BaseCustomField,
  Board,
  Clip,
  CustomFieldValue,
  ResponseCustomFieldValue,
} from '@air/api/types';
import produce from 'immer';
import { isNumber, uniqBy } from 'lodash';

import { CustomFieldValueWithFieldData } from '~/components/CustomFields/CustomFieldValue/types';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';

export const getCustomFieldValue = ({ value, values, plainTextValue, dateValue }: AssetCustomFieldValue) =>
  value || values || plainTextValue || dateValue;

export const getCustomFieldSingleAndMultiSelectValues = ({ value, values }: AssetCustomFieldValue) => value || values;

export const getCustomFieldPlainTextValues = ({ plainTextValue }: AssetCustomFieldValue) => plainTextValue;

export const getCustomFieldDateValues = ({ dateValue }: AssetCustomFieldValue) => dateValue;

export const getCustomFieldSelectOption = (value: ResponseCustomFieldValue): DefaultChipType => ({
  value: value.id,
  label: value.value,
  fontHexColor: value.color.fontHex,
  backgroundHexColor: value.color.backgroundHex,
});

interface GetFlattenedCustomFieldValuesParams {
  /**
    This represents the custom fields that actually have a value set on an asset or board
   */
  customFields: AssignedCustomFieldValue[];
}

export const getFlattenedCustomFieldValues = ({ customFields }: GetFlattenedCustomFieldValuesParams) => {
  const flattenedCustomFieldValues: CustomFieldValueWithFieldData[] = [];
  for (const cf of customFields) {
    if (cf.value) {
      flattenedCustomFieldValues.push({ ...cf.value, fieldId: cf.id, fieldName: cf.name });
    } else if (cf.values) {
      for (const v of cf.values) {
        flattenedCustomFieldValues.push({ ...v, fieldId: cf.id, fieldName: cf.name });
      }
    }
  }

  return flattenedCustomFieldValues;
};

export interface GetFieldsToRenderAndOverflowCountProps extends GetFlattenedCustomFieldValuesParams {
  /**
    This represents the width of the card we trying to not overflow
   */
  allowedWidth: number;
  /**
    This represents the width of each character. This is just a rough estimate. As a general rule
    prefer going with a larger number than a smaller number when in doubt.
   */
  widthPerChar: number;
}

export const getFieldsToRenderAndOverflowCount = ({
  customFields,
  allowedWidth,
  widthPerChar,
}: GetFieldsToRenderAndOverflowCountProps): {
  fieldsToRender: CustomFieldValueWithFieldData[];
  hiddenFieldsCount: number;
} => {
  const flattenedCustomFieldValues = getFlattenedCustomFieldValues({
    customFields,
  });

  let totalRenderedWidth = 0;
  let hiddenFieldsCount = 0;
  const fieldsToRender = [];

  for (const [index, customField] of flattenedCustomFieldValues.entries()) {
    const totalWidth = customField.value.length * widthPerChar;
    totalRenderedWidth += totalWidth;
    if (index !== 0 && totalRenderedWidth > allowedWidth) {
      hiddenFieldsCount = flattenedCustomFieldValues.length - index;
      break;
    }
    fieldsToRender.push(customField);
  }

  return {
    fieldsToRender,
    hiddenFieldsCount,
  };
};

export type UpdateItemCustomFieldValueParams = {
  baseCustomField: BaseCustomField;
  nextValue?: CustomFieldValue | null;
  nextValues?: CustomFieldValue[] | null;
  multiValuesToAdd?: CustomFieldValue[];
  multiValueIdsToRemove?: string[];
  nextPlainTextValue?: string;
  nextDateValue?: string;
};
export const updateClipCustomFieldsWithValue = ({
  assetId,
  baseCustomField,
  customFieldValues = [],
  nextValue,
  nextValues,
  multiValuesToAdd,
  multiValueIdsToRemove,
  nextPlainTextValue,
  nextDateValue,
}: UpdateItemCustomFieldValueParams & { customFieldValues: Clip['customFields']; assetId: Clip['assetId'] }) => {
  return produce(customFieldValues, (draft) => {
    const cfIndex = draft?.findIndex((cf) => cf.id === baseCustomField.id);
    const addedValues = nextValues || multiValuesToAdd || null;

    if (isNumber(cfIndex) && cfIndex !== -1) {
      // manipulate original value(s)
      const values = addedValues
        ? uniqBy([...(draft[cfIndex].values || []), ...addedValues], 'id').filter(
            (v) => !multiValueIdsToRemove || !multiValueIdsToRemove.includes(v.id),
          )
        : null;
      draft[cfIndex].value = nextValue || null;
      draft[cfIndex].plainTextValue = nextPlainTextValue;
      draft[cfIndex].dateValue = nextDateValue;
      draft[cfIndex].values = values;
    } else {
      // use baseCustomField to create the value and push it in
      const values = addedValues
        ? uniqBy(addedValues, 'id').filter((v) => !multiValueIdsToRemove || !multiValueIdsToRemove.includes(v.id))
        : null;
      draft.push({
        ...baseCustomField,
        assetId,
        value: nextValue || null,
        values,
        plainTextValue: nextPlainTextValue,
        dateValue: nextDateValue,
      });
    }
  });
};

export const updateBoardCustomFieldsWithValue = ({
  baseCustomField,
  customFieldValues = [],
  nextValue,
  nextValues,
  multiValuesToAdd,
  multiValueIdsToRemove,
  nextPlainTextValue,
  nextDateValue,
  boardId,
}: UpdateItemCustomFieldValueParams & {
  customFieldValues: Board['customFields'];
  boardId: Board['id'];
}): Board['customFields'] => {
  return produce(customFieldValues, (draft) => {
    const cfIndex = draft?.findIndex((cf) => cf.id === baseCustomField.id);
    const addedValues = nextValues || multiValuesToAdd || null;

    if (isNumber(cfIndex) && cfIndex !== -1) {
      // manipulate original value(s)
      const values = addedValues
        ? uniqBy([...(draft[cfIndex].values || []), ...addedValues], 'id').filter(
            (v) => !multiValueIdsToRemove || !multiValueIdsToRemove.includes(v.id),
          )
        : null;
      draft[cfIndex].value = nextValue || null;
      draft[cfIndex].plainTextValue = nextPlainTextValue;
      draft[cfIndex].dateValue = nextDateValue;
      draft[cfIndex].values = values;
    } else {
      const values = addedValues
        ? uniqBy(addedValues, 'id').filter((v) => !multiValueIdsToRemove || !multiValueIdsToRemove.includes(v.id))
        : null;
      // use baseCustomField to create the value and push it in
      draft.push({
        ...baseCustomField,
        boardId,
        value: nextValue || null,
        values,
        plainTextValue: nextPlainTextValue,
        dateValue: nextDateValue,
      });
    }
  });
};
