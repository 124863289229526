import pluralize from 'pluralize';
import { useMemo } from 'react';

export interface MenuSelectedHeaderProps {
  selectedCount: number;
}

export const MenuSelectedHeader = ({ selectedCount }: MenuSelectedHeaderProps) => {
  const itemsText = useMemo(() => pluralize('item', selectedCount, true), [selectedCount]);

  if (selectedCount <= 1) {
    return null;
  }

  return (
    <div className="-mx-1.5 -mt-1.5 flex h-10 items-center border-b border-b-grey-4 px-4">
      <div className="text-12 font-bold uppercase text-grey-10">{itemsText} selected</div>
    </div>
  );
};
