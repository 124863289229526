import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { GallerySection } from '@air/component-gallery-view';
import { useBreakpointsContext } from '@air/provider-media-query';
import { Upload } from '@air/redux-uploader';
import { constant, noop } from 'lodash';
import { useCallback, useMemo } from 'react';

import { CardSize } from '~/classes/CardSizeStore';
import { GalleryItemType, GalleryViewRender } from '~/components/Gallery/types';
import { UploadingAssetGalleryCard } from '~/components/Gallery/UploadingAssetGalleryCard';
import { useGallerySectionFooter } from '~/components/Gallery/useGallerySectionFooter';
import { useGallerySectionHeader } from '~/components/Gallery/useGallerySectionHeader';
import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';
import DragType from '~/components/Shared/Drag/dragTypes';
import { useJustifiedGrid } from '~/components/Shared/JustifiedGrid';
import { WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN } from '~/constants/WorkspaceSpacing';
import { getClipRatio } from '~/hooks/useClipRatios';
import { UseGalleryDataInfo } from '~/swr-hooks/gallery/types';

export const AssetGalleryCardSize: { [key in CardSize]: number } = {
  small: 165,
  medium: 220,
  large: 440,
  'extra-large': 660,
};

export interface UseGalleryAssetsParams<C> {
  data?: UseGalleryDataInfo<C>;
  containerWidth: number;
  renderAsset: ((props: GalleryViewRender<C>) => JSX.Element) | undefined;
  loadMore?: () => void;
  uploads?: Upload[];
  isFirstSection: boolean;
  containerHorizontalPadding?: number;
  itemAdditionalHeight?: number;
  itemHeight: number;
  isSelectable?: (clip: C) => boolean;
  onSectionCollapse?: (isCollapsed: boolean) => void;
}

export const useGalleryAssets = <C extends Clip | PublicClip>({
  renderAsset,
  containerWidth,
  loadMore = noop,
  data,
  uploads = [],
  itemHeight,
  isFirstSection,
  onSectionCollapse,
  containerHorizontalPadding = WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN,
  itemAdditionalHeight = 0,
  isSelectable = constant(false),
}: UseGalleryAssetsParams<C>): GallerySection<GalleryMetadata> | null => {
  const { isAboveSmallScreen } = useBreakpointsContext();

  const assets = useMemo(
    () => [
      ...uploads.map((u) => ({
        item: u,
        type: GalleryItemType.upload,
      })),
      ...(data?.items ?? []).map((u) => ({
        item: u,
        type: GalleryItemType.asset,
      })),
    ],
    [data?.items, uploads],
  );

  const total = (data?.total ?? 0) + uploads.length;

  const ratios = [
    ...uploads.map(({ apiUploadData }) => ({
      width: 0,
      height: 0,
      ...apiUploadData,
    })),
    ...(data?.items || []),
  ].map((clip) => getClipRatio(clip));

  const { boxes: assetBoxes } = useJustifiedGrid({
    containerWidth: containerWidth,
    ratios,
    options: {
      containerPadding: {
        left: containerHorizontalPadding,
        right: containerHorizontalPadding,
      },
      targetRowHeight: itemHeight,
      boxSpacing: {
        horizontal: 24,
      },
      additionalRowHeight: itemAdditionalHeight,
    },
  });

  const renderItem = useCallback(
    (index: number) => {
      const asset = assets[index];
      if (asset) {
        return asset.type === GalleryItemType.upload ? (
          <UploadingAssetGalleryCard
            width={
              /**
               * Uploads don't inherently have a width and get it from the ratio of the
               * row height
               */
              itemHeight
            }
            upload={asset.item as Upload}
          />
        ) : (
          renderAsset?.({
            box: assetBoxes[index],
            data: asset.item as C,
            index,
          })
        );
      }
      return null;
    },
    [assets, itemHeight, renderAsset, assetBoxes],
  );

  const verticalSpacing = !isAboveSmallScreen ? 12 : 24;

  const getItemData = useCallback(
    (index: number): GalleryMetadata => ({
      type: assets[index].type,
      isSelectable: assets[index].type === GalleryItemType.asset ? isSelectable(assets[index].item as C) : false,
      itemId: assets[index].item.id,
      canDragTo: (item) => item.type === DragType.asset,
    }),
    [assets, isSelectable],
  );

  const { isHeaderCollapsed, header } = useGallerySectionHeader({
    isFirstSection,
    title: `Assets (${total.toLocaleString()})`,
    containerWidth,
    containerPadding: containerHorizontalPadding,
    onSectionCollapse,
  });

  const footer = useGallerySectionFooter({
    isLoading: !!data?.isLoading,
    loadMore,
    hasMore: !!data?.hasMore,
    containerWidth,
  });

  if (!total && !data?.isLoading) {
    return null;
  }

  return {
    header: !!total ? header : undefined,
    footer: isHeaderCollapsed ? undefined : footer,
    items: {
      render: renderItem,
      boxes: isHeaderCollapsed ? [] : assetBoxes,
      verticalItemsSpacing: verticalSpacing,
      getData: getItemData,
    },
  };
};
