import { ActivatedAccount } from '@air/analytics';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo } from 'react';

import { EmailSignUpChecker } from '~/components/Auth/EmailSignUpChecker';
import { DividerText } from '~/components/DividerText';
import { SamlSSOLoginModal } from '~/components/Modals/SamlSSOLoginModal';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { SSOButtons } from '~/components/SSOButtons';

interface SignUpModalSSOOrEmailFormProps {
  onEmailSubmit: (email: string) => void;
  ssoCallbackData?: SSOCallbackData;
  accountCreationSource: ActivatedAccount['creationSource'];
  onLoginClick: () => void;
  closeModal: () => void;
}

export const SignUpModalSSOOrEmailForm = memo(
  ({
    onEmailSubmit,
    ssoCallbackData,
    accountCreationSource,
    onLoginClick,
    closeModal,
  }: SignUpModalSSOOrEmailFormProps) => {
    const { showToast } = useToasts();
    const [showSAMLSSOModal] = useAirModal(SamlSSOLoginModal);

    const { maintenanceModeJson } = useFlags();

    const openSAMLSSOModal = () => {
      closeModal();

      // add a short delay to avoid blinking when one modal is closing, and the second one appears
      setTimeout(
        () =>
          showSAMLSSOModal({
            onShowLoginClick: onLoginClick,
            ssoCallbackData,
            customState: {
              creationSource: accountCreationSource,
            },
          }),
        200,
      );
    };

    return (
      <div>
        {ssoCallbackData && (
          <SSOButtons
            verbText="Sign up"
            tx={{
              GoogleButton: {
                mb: 8,
              },
            }}
            ssoCallbackData={ssoCallbackData}
            onContinueWithSamlClick={openSAMLSSOModal}
            customState={{
              creationSource: accountCreationSource,
            }}
          />
        )}
        <DividerText />
        <EmailSignUpChecker
          onSuccess={({ email }) => {
            onEmailSubmit(email);
          }}
          onError={() => {
            showToast('Something went wrong. Please try again later.');
          }}
          disabled={maintenanceModeJson?.maintenanceMessage}
        />
      </div>
    );
  },
);

SignUpModalSSOOrEmailForm.displayName = 'SignUpModalSSOOrEmailForm';
