import { Box, BoxProps } from '@air/zephyr';
import React from 'react';

type SSOButtonsContainerProps = BoxProps;

export const SSOButtonsContainer = ({ tx, ...props }: SSOButtonsContainerProps) => (
  <Box
    tx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > button': { width: '100%' },
      '& > button > div': { color: 'pigeon600', textAlign: 'left', width: '155px' },
      ...tx,
    }}
    {...props}
  />
);
